<template>
<!-- 消息中心 -->
<div class="box100">
  <div class="specialForm">
    <div class="specialFL">
      <div class="sflName" :class="messageType === '1' ? 'sfActive': ''" @click="changeForm('1')">
        <i class="iconfont icon-msgyuy"></i>
        <span>预约消息</span>
        <span class="msNum" v-if="makeNum > 0">{{makeNum}}</span>
      </div>
      <div class="sflName" :class="messageType === '2' ? 'sfActive': ''" @click="changeForm('2')">
        <i class="iconfont icon-msgtk"></i>
        <span>退款消息</span>
        <span class="msNum" v-if="refundNum > 0">{{refundNum}}</span>
      </div>
<!--      <div class="sflName" :class="messageType === '2' ? 'sfActive': ''" @click="changeForm('2')">-->
<!--        <i class="iconfont icon-msgxt"></i>-->
<!--        <span>系统消息</span>-->
<!--      </div>-->
    </div>
    <div class="infoFR" style="padding: 0;"  :class="messageArray.length === 0 ? 'whiteBg': ''">
      <!-- 预约消息内容 -->
      <div class="box100 scrollBox">
        <div class="msgone flexCB" v-for="(item, index) in messageArray" :key="index" @click="goDetail(item)">
          <div class="text_info">
            <p class="text_b">{{ item.creatime }}</p>
            <div>
              <span>{{item.megdata.other}}</span>
              <span v-if="item.megdata.type == 1" style="color: #3C84EF;">{{item.megdata.type_name}},</span>
              <span v-if="item.megdata.type == 2" style="color: #F44C8E;">{{item.megdata.type_name}},</span>
              <span v-if="item.megdata.type == 3" style="color: #44B557;">{{item.megdata.type_name}},</span>
              <span v-if="item.megdata.type == 4" style="color: #3C84EF;">{{item.megdata.type_name}},</span>
              <span v-if="item.megdata.type == 5" style="color: #F44C8E;">{{item.megdata.type_name}},</span>
              <span>{{item.megdata.mark}}</span>
            </div>
          </div>
          <div class="cicle" v-if="item.mesisok === 0"></div>
        </div>
        <div v-if="messageArray.length === 0" style="margin-top: 30%;text-align: center;">暂无数据</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mesQuery, msgIdRead } from '@/api/system'
export default {
  name: 'index',
  data () {
    return {
      messageType: '1',
      messageArray: [],
      makeNum: 0, // 预约未读数量
      refundNum: 0 // 退款未读数量
    }
  },
  watch: {
    '$route.query.type': function () {
      this.messageType = this.$route.query.type
      this.getMesList()
    }
  },
  created () {
    this.messageType = this.$route.query.type
    this.getMesList()
  },
  methods: {
    getMesList () {
      mesQuery({ megtype: this.messageType }).then(res => {
        if (res.status === 1) {
          this.makeNum = res.data.count[0]
          this.refundNum = res.data.count[1]
          this.messageArray = res.data.list
        }
      })
    },
    changeForm (type) {
      this.messageType = type
      this.getMesList()
    },
    /* 点击-消息详情 */
    goDetail (item) {
      msgIdRead({ msgid: item.msgid }).then(res => {
        if (res.status === 1) {
          this.getMesList()
          if (item.megtype === 1) { /* 跳转预约 */
            this.$emit('handMsgRead', '1')
            this.$router.push({
              name: 'receptionOrder',
              params: {
                megasid: item.megasid
              }
            })
          } else if (item.megtype === 2) { /* 跳转订单 */
            this.$emit('handMsgRead', '2')
            this.$router.push({
              name: 'afterRefund',
              params: {
                megasid: item.megasid
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.iconfont {
  font-size: 14px;
  margin-right: 5px;
}
.infoFR {
  width: calc(100% - 200px);
  height: 100%;
  position: relative;
}
.msgone{
  /*height: 60px;*/
  background: #FFFFFF;
  padding: 12px 20px;
  box-sizing: border-box;
  border-bottom: 8px solid #F7F9FB;
  cursor: pointer;
}
.text_b {
  font-size: 12px;
  font-weight: 400;
  color: #A5B7CC;
  margin-bottom: 5px;
}
.cicle {
  width: 10px;
  height: 10px;
  background: #F44C8E;
  border-radius: 50%;
}
</style>
